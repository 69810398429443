import { SVGProps, Ref, forwardRef } from "react";

const LightbulbIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 20 19"
    fill="none"
    stroke="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Lightbulb02 Icon"
    {...props}
  >
    <title id="Lightbulb02 Icon">Lightbulb Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M8.335 13.946v1.951a1.667 1.667 0 0 0 3.333 0v-1.951M10.001.897v.833m-7.5 7.5h-.833m2.917-5.416-.5-.5m11.333.5.5-.5m2.417 5.916H17.5m-2.5 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
    />
  </svg>
);

const ForwardRef = forwardRef(LightbulbIcon);
export default ForwardRef;
