import {
    ChangeEvent,
    DragEvent,
    forwardRef,
    useEffect,
    useMemo,
    useState,
  } from "react";
  import { Trans, useTranslation } from "next-i18next";
  import { Button } from "../Button";
  import { CloudUploadIcon } from "@talladega/icons";
  
  export interface FileUploadError {
    file?: File;
    message: string;
  }
  
  const defaultMaxFileSize = 10485760; // 10 MB
  const defaultMaxNumberOfFiles = 2;
  export const fileExtensions = [
    "png",
    "jpg",
    "pdf",
    "tiff",
    "bmp",
    "heic",
    "heif",
    "webp",
    "gif",
    "jfif",
  ] as const;
  
  export const fileTypes = [
    "image/png",
    "image/jpeg",
    "image/tiff",
    "image/bmp",
    "image/heic",
    "image/heif",
    "image/webp",
    "image/gif",
    "application/pdf",
  ] as const;
  
  type FileExtension = (typeof fileExtensions)[number];
  export type FileType = (typeof fileTypes)[number];
  
  export const fileFormats = new Map<FileType, FileExtension>();
  fileFormats.set("image/bmp", "bmp");
  fileFormats.set("image/jpeg", "jpg");
  fileFormats.set("image/heic", "heic");
  fileFormats.set("image/heif", "heif");
  fileFormats.set("image/webp", "webp");
  fileFormats.set("application/pdf", "pdf");
  fileFormats.set("image/png", "png");
  fileFormats.set("image/tiff", "tiff");
  fileFormats.set("image/gif", "gif");
  fileFormats.set("image/jpeg", "jfif");
  
  const validateFileType = (
    type: FileType,
    allowedFileTypes: FileType[]
  ): boolean => {
    return !!allowedFileTypes.find((t) => type === t);
  };
  
  // Image types that are not supported by browsers have
  // empty File `type` property so derive it from file extension instead.
  const getFileType = (file: File) => {
    const fileExtension = file.name.split(".").slice(-1).pop()?.toLowerCase();
    const fileType = Array.from(fileFormats.entries()).find(
      ([, v]) => v === fileExtension
    )?.[0];
  
    return fileType;
  };
  
  export const validateFile = (file: File, allowFileTypes: FileType[]) => {
    const fileType = file?.type || getFileType(file);
    let isValidFileType = true;
  
    if (fileType) {
      isValidFileType = validateFileType(fileType as FileType, allowFileTypes);
    } else {
      isValidFileType = false;
    }
  
    const isValidFileSize = file?.size <= defaultMaxFileSize;
  
    return {
      isValidFileType,
      isValidFileSize,
    };
  };
  
  export type FileUploadProps = {
    allowMultiple?: boolean;
    allowFileTypes: FileType[];
    preview?: boolean;
    showCameraInput?: boolean;
    maxNumberOfFiles?: number;
    onChange?: (files: File[]) => void;
    onError?: (errors: FileUploadError[]) => void;
  };
  
  export type FileUploadPreviewProps = {
    file: File;
    index: number;
    preview?: boolean;
    onDelete: (index: number) => void;
    showDelete?: boolean;
  };
  
  type FileUploadPreviewListProps = {
    files: File[];
  } & Pick<FileUploadPreviewProps, "preview" | "showDelete" | "onDelete">;
  
  const FileUploadPreview = ({
    file,
    index,
    preview,
    onDelete,
    showDelete = true,
  }: FileUploadPreviewProps) => {
    const { t } = useTranslation(["common-components"]);
  
    const state = useMemo(() => {
      const previewUrl = URL.createObjectURL(file);
      const { size } = file;
  
      return {
        previewUrl,
        size,
      };
    }, [file]);
  
    return (
      <div key={index} className="flex items-center justify-between">
        <div>
          {(preview || state.size === 0) && (
            <span className="text-sm font-medium">{file?.name}</span>
          )}
          {!preview && state.size !== 0 && (
            <a
              href={state.previewUrl}
              target="_blank"
              rel="noreferrer"
              className="text-sm font-medium text-primary-500"
            >
              {file?.name}
            </a>
          )}
        </div>
        {showDelete && (
          <Button
            type="button"
            fill="link"
            size="small"
            spacing="tight-hug"
            buttonStyle="danger"
            onClick={() => onDelete(index)}
          >
            {t("common-components:delete")}
          </Button>
        )}
      </div>
    );
  };
  
  export const FileUploadPreviewList = ({
    files,
    preview = false,
    onDelete,
    showDelete,
  }: FileUploadPreviewListProps) => {
    return (
      <div className="divide-y divide-gray-100 py-4">
        {files.map((f, index) => (
          <FileUploadPreview
            file={f}
            preview={preview}
            onDelete={onDelete}
            showDelete={showDelete}
            index={index}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </div>
    );
  };
  
  export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
    (
      {
        allowMultiple = false,
        preview = false,
        showCameraInput = true,
        allowFileTypes,
        maxNumberOfFiles = defaultMaxNumberOfFiles,
        onChange,
        onError,
      }: FileUploadProps,
      ref
    ) => {
      const { t } = useTranslation(["common-components"]);
      const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
      const [, setErrors] = useState<FileUploadError[]>([]);
  
      const handleFiles = (fileList: File[]) => {
        const updatedFiles: File[] = [];
        const updatedErrors: FileUploadError[] = [];
  
        // Clear validation errors
        setErrors(updatedErrors);
  
        if (fileList.length === 0) return;
  
        let filesToValidate = [...fileList];
  
        if (allowMultiple) {
          // Validate total files selected
          const totalFilesSelected =
            selectedFiles.length + filesToValidate.length;
  
          if (totalFilesSelected > maxNumberOfFiles) {
            if (onError) {
              onError([
                {
                  message: t("common-components:maximum_files_exceeded"),
                },
              ]);
  
              return;
            }
          }
        } else {
          const [firstFile] = fileList;
          filesToValidate = firstFile ? [firstFile] : [];
        }
  
        filesToValidate.forEach((file: File) => {
          const { isValidFileType, isValidFileSize } = validateFile(
            file,
            allowFileTypes
          );
  
          if (isValidFileType && isValidFileSize) {
            updatedFiles.push(file);
          } else {
            if (!isValidFileType) {
              updatedErrors.push({
                file,
                message: t("common-components:invalid_file_type"),
              });
            }
  
            if (!isValidFileSize) {
              updatedErrors.push({
                file,
                message: t("common-components:invalid_file_size"),
              });
            }
          }
        });
  
        if (updatedErrors.length === 0) {
          setSelectedFiles((prevFiles) => {
            let files = updatedFiles;
  
            if (allowMultiple) {
              files = [...prevFiles, ...updatedFiles];
            }
  
            return files;
          });
        }
  
        setErrors((prevErrors) => {
          if (allowMultiple) {
            return [...prevErrors, ...updatedErrors];
          }
  
          return updatedErrors;
        });
  
        if (onError) onError(updatedErrors);
      };
  
      const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const fileList = Array.from(e.target.files ?? []);
  
        handleFiles(fileList);
  
        // Reset input value to trigger `onChange` for the same file
        // https://stackoverflow.com/questions/19643265/second-use-of-input-file-doesnt-trigger-onchange-anymore
        e.target.value = "";
      };
  
      const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
      };
  
      const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
  
        const fileList = Array.from(e.dataTransfer.files);
  
        handleFiles(fileList);
      };
  
      const handleDelete = (index: number) => {
        setSelectedFiles((prevFiles) => {
          const updatedFiles = prevFiles.filter((_, i) => i !== index);
  
          if (onChange) onChange(updatedFiles);
  
          return updatedFiles;
        });
      };
  
      const handleCapture = (e: ChangeEvent<HTMLInputElement>) => {
        const capturedFile = e.target?.files?.[0];
  
        if (!capturedFile) return;
  
        handleFiles([capturedFile]);
  
        e.target.value = "";
      };
  
      useEffect(() => {
        onChange?.(selectedFiles);
        // `onChange` is not a dependency because it will cause infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedFiles]);
  
      return (
        <>
          <div
            className="flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-100 bg-gray-25 p-4"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <span className="pb-6 pt-4">
              <CloudUploadIcon className="h-8 w-8 text-gray-500" />
            </span>
            <label
              className="text-sm font-medium text-center text-primary-500 hover:cursor-pointer"
              htmlFor="cameraFileInput"
            >
              {showCameraInput && <>{t("common-components:take_a_picture_with_phone")}</>}
  
              <input
                id="cameraFileInput"
                className="hidden"
                type="file"
                accept={allowFileTypes.join(",")}
                capture="user"
                onChange={handleCapture}
              />
            </label>
            <p className="text-sm font-medium pb-8 text-center">
              <Trans
                t={t}
                i18nKey="common-components:drag_and_drop_or_browse"
                components={{
                  label: (
                    // eslint doesn't seem to detect the associated control inside Trans component
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label
                      className="text-primary-500 hover:cursor-pointer"
                      htmlFor="fileInput"
                    />
                  ),
                }}
              />
              <input
                id="fileInput"
                className="hidden"
                type="file"
                accept={allowFileTypes.join(",")}
                multiple={allowMultiple}
                onChange={handleFileChange}
                ref={ref}
              />
            </p>
          </div>
  
          {selectedFiles.length > 0 && (
            <FileUploadPreviewList
              files={selectedFiles}
              onDelete={handleDelete}
              preview={preview}
            />
          )}
        </>
      );
    }
  );
  