import { DotButton } from "./DotButton";

type Props = {
  length: number;
  selectedIndex: number;
  onClick?: (index: number) => void;
};

export const CarouselDots = ({ length, selectedIndex, onClick }: Props) => {
  return (
    <div className="flex gap-2.5">
      {[...Array(length)].map((_, index) => (
        <DotButton
          selected={index === selectedIndex}
          onClick={() => onClick?.(index)}
          buttonColours="secondary"
          // eslint-disable-next-line react/no-array-index-key
          key={`dot-button-${index}`}
        />
      ))}
    </div>
  );
};
