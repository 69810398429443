import {
    createContext,
    Fragment,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
  } from "react";
  import { Dialog, Transition } from "@headlessui/react";
  import classNames from "classnames";
  import {
    CheckCircleIcon,
    CloseIcon,
    InfoCircleIcon,
    WarningIcon,
  } from "@talladega/icons";
import { Button, ButtonFill, ButtonSpacing, ButtonStyle } from "../Button";
import { ModalAlignment, ModalButtonDisplay, ModalProps } from "./ModalProps";

  
  // state to share between Modal and ModalButtons
  const ModalContext = createContext<{
    alignment: ModalAlignment;
    buttonDisplay: ModalButtonDisplay;
    onClose(): void;
  }>({
    alignment: "left",
    buttonDisplay: "block",
    onClose: () => null,
  });
  
  type ModalButtonsProps = {
    onPrimaryClick?: () => void;
    onSecondaryClick?: () => void;
    primaryButtonText: string;
    secondaryButtonText?: string;
    secondaryButtonFill?: ButtonFill;
    primaryDisabled?: boolean;
    isPrimarySubmit?: boolean;
    removeExtraPadding?: boolean;
    primaryButtonSpacing?: ButtonSpacing;
    secondaryButtonSpacing?: ButtonSpacing;
    hideSecondaryButton?: boolean;
    stretchButtonToContent?: boolean;
    primaryButtonRightIcon?: ReactNode;
    primaryButtonStyle?: ButtonStyle;
  };
  
  const ModalButtons = ({
    primaryButtonText,
    secondaryButtonText,
    secondaryButtonFill = "outline",
    onPrimaryClick,
    onSecondaryClick,
    primaryButtonSpacing = "hug",
    secondaryButtonSpacing = "hug",
    primaryDisabled = false,
    isPrimarySubmit = false,
    removeExtraPadding = false, // this property is temporary, we will add a real fix soon and remove code that uses this
    hideSecondaryButton = false,
    stretchButtonToContent = false,
    primaryButtonRightIcon,
    primaryButtonStyle = "primary",
  }: ModalButtonsProps) => {
    const { onClose, alignment, buttonDisplay } = useContext(ModalContext);
  
    // default to onClose if not supplied
    const primaryClick =
      onPrimaryClick || (isPrimarySubmit ? undefined : onClose);
    const secondaryClick = onSecondaryClick || onClose;
  
    return (
      <>
        {buttonDisplay === "block" && (
          <div
            className={classNames("py-7 flex flex-col gap-2", {
              "inline-flex": stretchButtonToContent,
              "px-6 sm:px-28 ": !stretchButtonToContent,
              "sm:flex-row-reverse": alignment === "left",
              "justify-center": alignment === "center",
            })}
          >
            <Button
              spacing={primaryButtonSpacing}
              onClick={primaryClick}
              disabled={primaryDisabled}
              isSubmit={isPrimarySubmit}
              aria-label={`${primaryButtonText}`}
              rightIcon={primaryButtonRightIcon}
              buttonStyle={primaryButtonStyle}
            >
              {primaryButtonText}
            </Button>
  
            {!hideSecondaryButton && secondaryButtonText && (
              <Button
                fill={secondaryButtonFill}
                spacing={secondaryButtonSpacing}
                onClick={secondaryClick}
                className={classNames({
                  underline: secondaryButtonFill === "link",
                })}
                aria-label={`${secondaryButtonText}`}
              >
                {secondaryButtonText}
              </Button>
            )}
          </div>
        )}
        {buttonDisplay === "inline" && (
          <div
            className={classNames("py-7 flex flex-row gap-2", {
              "sm:flex-row-reverse": alignment === "left",
              "px-6 sm:px-28 justify-center":
                !removeExtraPadding && alignment === "center",
              "px-6 justify-center": removeExtraPadding && alignment === "center",
            })}
          >
            {!hideSecondaryButton && secondaryButtonText && (
              <Button
                fill={secondaryButtonFill}
                spacing={secondaryButtonSpacing}
                onClick={secondaryClick}
                className={classNames("inline-block md:min-w-[12rem]", {
                  underline: secondaryButtonFill === "link",
                })}
              >
                {secondaryButtonText}
              </Button>
            )}
  
            <Button
              spacing={primaryButtonSpacing}
              onClick={primaryClick}
              disabled={primaryDisabled}
              isSubmit={isPrimarySubmit}
              rightIcon={primaryButtonRightIcon}
              className="inline-block md:min-w-[12rem]"
            >
              {primaryButtonText}
            </Button>
          </div>
        )}
      </>
    );
  };
  
  export const Modal: React.FunctionComponent<ModalProps> & {
    Buttons: typeof ModalButtons;
  } = ({
    isOpen,
    onClose,
    children,
    title,
    contentHeading,
    alignment = "left",
    buttonDisplay = "block",
    modalType = "none",
    iconBackGroundType = "square",
    customIcon,
    fitWidthToContent = false,
    isPopup = false,
    isPopupImageTop = false,
    disableClosing = false,
    themeClassNames,
    removeBottomPadding = false,
  }: ModalProps) => {
    const modalContextValue = useMemo(
      () => ({ alignment, onClose, buttonDisplay }),
      [alignment, buttonDisplay, onClose]
    );
    const isCenterAligned = alignment === "center";
    const iconSize = "h-8 w-8";
    const iconBackgroundShape =
      iconBackGroundType === "square" ? "rounded-2xl" : "rounded-full";
    const defaultIconContainerStyling = `flex-shrink-0 flex items-center justify-center ${iconBackgroundShape} sm:mx-0`;
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        setIsModalOpen(true);
      } else if (!isOpen) {
        setIsModalOpen(false);
      }
    }, [isOpen]);
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const modalIcon = {
      warning: (
        <div
          className={classNames(`${defaultIconContainerStyling} bg-error-50`, {
            "p-1.5": !isCenterAligned,
            "p-3.5": isCenterAligned,
          })}
        >
          <WarningIcon className={classNames("text-error-primary", iconSize)} />
        </div>
      ),
      info: (
        <div
          className={classNames(
            `${defaultIconContainerStyling} bg-secondary-100`,
            { "p-1.5": !isCenterAligned, "p-3.5": isCenterAligned }
          )}
        >
          <InfoCircleIcon
            className={classNames("text-secondary-500", iconSize)}
          />
        </div>
      ),
      success: (
        <div
          className={classNames(`${defaultIconContainerStyling} bg-success-50`, {
            "p-1.5": !isCenterAligned,
            "p-3.5": isCenterAligned,
          })}
        >
          <CheckCircleIcon className={classNames("text-success-300", iconSize)} />
        </div>
      ),
      none: "",
    };
  
    if (!(modalType in modalIcon)) {
      throw Error("Modal type not supported");
    }
  
    return (
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 overflow-auto w-[80rem]"
          onClose={
            disableClosing
              ? () => {
                  // do nothing if we disable closing
                  // onClose is required so we need to pass empty function
                }
              : closeModal
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-400-hsl/75 transition-opacity" />
          </Transition.Child>
  
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            /**
             * prevent the click event from clicking the modal overlay from bubbling up to the parent component
             * clicking modal overlay should close modal and not trigger any side-effects
             */
            onClick={(e) => e.stopPropagation()}
            onKeyDown={() => undefined}
            className="fixed z-10 inset-0 overflow-y-auto"
          >
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0"
                afterLeave={() => {
                  onClose();
                }}
              >
                <Dialog.Panel
                  className={classNames(
                    `${
                      themeClassNames || ""
                    } relative bg-white rounded-lg text-left overflow-initial no-scrollbar shadow-elevation-03 transform transition-all sm:my-1 w-full`,
                    {
                      "max-w-lg md:max-w-3xl": isPopup && !isPopupImageTop,
                      "p-4 pb-8": !isPopup && !removeBottomPadding,
                      "p-4": !isPopup && removeBottomPadding,
                      "max-w-lg": !fitWidthToContent,
                      "max-w-fit": fitWidthToContent,
                    }
                  )}
                >
                  {!isPopup && (
                    <>
                      <div className="relative">
                        <div className="mx-12 text-center">
                          {isCenterAligned && (
                            <div className="flex flex-col items-center gap-4">
                              <Dialog.Title
                                as="h3"
                                className="text-lg font-semibold text-primary-500 kijiji:text-gray-600"
                              >
                                {title}
                              </Dialog.Title>
                            </div>
                          )}
                          {!isCenterAligned && (
                            <div className="flex items-center gap-3">
                              <Dialog.Title
                                as="h3"
                                className="text-xl leading-6 font-semibold text-primary-500 kijiji:text-gray-600"
                              >
                                {title}
                              </Dialog.Title>
                            </div>
                          )}
                        </div>
                        {!disableClosing && (
                          <div
                            className={classNames("absolute -top-2 -right-2", {
                              "-top-3": isCenterAligned,
                            })}
                          >
                            <Button
                              buttonStyle="primary"
                              fill="link"
                              onClick={closeModal}
                              size="small"
                              spacing="hug"
                              aria-label="Close"
                            >
                              <CloseIcon className="h-5 w-5 hover:cursor-pointer" />
                            </Button>
                          </div>
                        )}
                      </div>
                      <div
                        className={classNames({
                          "text-left mt-4": !isCenterAligned,
                          "text-center mt-1": isCenterAligned,
                        })}
                      >
                        <div className="text-base text-gray-500 min-h-[0.5rem]">
                          {title && (
                            <hr className="border-t border-t-solid border-gray-50" />
                          )}
                          {!title && <div className="mb-8" />}
                          <span className="flex justify-center my-3">
                            {customIcon && customIcon}
                            {!customIcon && modalIcon[modalType]}
                          </span>
                          {contentHeading && (
                            <h5 className="text-center text-primary break-words pb-2">
                              {contentHeading}
                            </h5>
                          )}
                          <ModalContext.Provider value={modalContextValue}>
                            {children}
                          </ModalContext.Provider>
                        </div>
                      </div>
                    </>
                  )}
                  {isPopup && !disableClosing && (
                    <>
                      <Button
                        buttonStyle="primary"
                        fill="link"
                        onClick={closeModal}
                        size="small"
                        spacing="tight-hug"
                        className="absolute right-0 z-50"
                        aria-label="Close"
                      >
                        <CloseIcon className="h-5 w-5 hover:cursor-pointer" />
                      </Button>
                      <ModalContext.Provider value={modalContextValue}>
                        {children}
                      </ModalContext.Provider>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  
  Modal.Buttons = ModalButtons;