import {
  Children,
  ReactNode,
  createContext,
  isValidElement,
  useContext,
  useMemo,
  useRef,
} from "react";
import StepTransitionGroupContext, {
  StepTransitionGroupType,
} from "./StepTransitionGroup";

interface StepperContextType {
  activeStep: number;
  linear: boolean;
  connector?: ReactNode;
}

const StepperContext = createContext<StepperContextType>(
  {} as StepperContextType
);

export function useStepperContext() {
  return useContext(StepperContext);
}

type Props = {
  activeStep: number;
  stepsLength: number;
  children: ReactNode;
  linear: boolean;
  connector?: ReactNode;
  /**
   * substracts the top position of the scroll target in pixel, useful for scenarios where the target is below a fixed positioned header
   */
  scrollOffsetTop?: number;
};

export const Stepper = ({
  activeStep,
  stepsLength,
  children,
  linear,
  connector,
  scrollOffsetTop = 0,
}: Props) => {
  const contextValue: StepperContextType = useMemo(
    () => ({
      activeStep,
      linear,
      connector,
    }),
    [activeStep, connector, linear]
  );

  const stepsRef = useRef<HTMLDivElement[]>([...Array(stepsLength)]);

  const transitionContextValue: StepTransitionGroupType = useMemo(
    () => ({
      activeStep,
      stepsLength,
      stepsRef,
      scrollOffsetTop,
    }),
    [activeStep, scrollOffsetTop, stepsLength]
  );

  const childrenArray = Children.toArray(children).filter((step) =>
    isValidElement(step)
  );

  return (
    <StepperContext.Provider value={contextValue}>
      <StepTransitionGroupContext.Provider value={transitionContextValue}>
        {childrenArray}
      </StepTransitionGroupContext.Provider>
    </StepperContext.Provider>
  );
};
