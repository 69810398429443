import { ReactNode, useState } from "react";
import { Transition } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@talladega/icons";
import { AccordionProps } from "./AccordionProps";

export const Accordion = ({ title, children, isOpen = false, onOpen }: AccordionProps) => {
  const [isContentOpen, setIsContentOpen] = useState(isOpen);

  const handleOnOpen = () => {
    setIsContentOpen(!isContentOpen);
    onOpen?.(!isContentOpen);
  };

  return (
    <div className="items-center justify-between">
      <button
        className="w-full flex items-center justify-between group text-left"
        type="button"
        onClick={() => handleOnOpen()}
      >
        <p className="text-primary text-base font-medium">{title}</p>
        <div className="p-2 group-hover:border-0 group-hover:bg-gray-50 group-hover:rounded-full">
          {isContentOpen ? (
            <ChevronUpIcon className="w-5 h-5 text-primary-500" />
          ) : (
            <ChevronDownIcon className="w-5 h-5 text-primary-500" />
          )}
        </div>
      </button>
      <Transition
        show={isContentOpen}
        as="div"
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </div>
  );
};
