import { SVGProps, Ref, forwardRef } from "react";

const ArrowDiagonalUpRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Arrow Diagonal Up Right Icon"
    {...props}
  >
    <title id="Arrow Diagonal Up Right Icon">
      Arrow Diagonal Up Right Icon
    </title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 15l6-6m0 0H9m6 0v6"
    />
  </svg>
);

const ForwardRef = forwardRef(ArrowDiagonalUpRightIcon);
export default ForwardRef;

