import { useState } from "react";
import classNames from "classnames";

type ToggleButtonType = "radio" | "checkbox";

type Props = {
  type: ToggleButtonType;
  children: Array<JSX.Element>;
  onChange: (selected: Array<string>) => void;
  selected?: Array<string>;
  className?: string;
};

export const ButtonGroup = ({
  type,
  children,
  onChange,
  selected = [],
  className = "",
}: Props) => {
  const [selectedButtons, setSelectedButtons] = useState<string[]>(selected);

  const activeButtonStyle = "bg-primary-500 text-white";
  const inactiveButtonStyle = "bg-white hover:bg-gray-50 text-primary-500";
  const commonButtonStyle =
    "-ml-px relative items-center px-4 py-2 border border-gray-100 text-sm font-medium first:rounded-l-md last:rounded-r-md";

  const handleClick = (button: HTMLButtonElement) => {
    const isSelected = !!selected.find((b) => b === button.value);

    if (type === "checkbox") {
      if (isSelected) {
        setSelectedButtons((prevSelectedButtons) => {
          return prevSelectedButtons.filter((btn) => btn !== button.value);
        });
      } else {
        setSelectedButtons((prevSelectedButtons) => {
          return [...prevSelectedButtons, button.value];
        });
      }

      return;
    }

    setSelectedButtons([button.value]);
    onChange([button.value]);
  };

  const setButtonStyle = (button: HTMLButtonElement): string => {
    let style = commonButtonStyle;

    if (selectedButtons.some((btn) => btn === button.value)) {
      style = style.concat(" ", activeButtonStyle);
    } else {
      style = style.concat(" ", inactiveButtonStyle);
    }

    return style;
  };

  return (
    <div
      className={classNames(
        className,
        "relative z-0 inline-flex flex-wrap shadow-sm"
      )}
    >
      {children.map((button, index) => (
        <button
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          type="button"
          onClick={() => {
            handleClick(button.props);
          }}
          className={classNames(
            button.props.className,
            setButtonStyle(button.props)
          )}
        >
          {button.props.children}
        </button>
      ))}
    </div>
  );
};
