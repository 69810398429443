import { SVGProps, Ref, forwardRef } from "react";

const ArrowSquareDiagonalUpRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="1em"
    aria-hidden="true"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-label="Arrow Square Diagonal Up Right Icon"
    {...props}
  >
    <title id="Arrow Square Diagonal Up Right Icon">
      Arrow Square Diagonal Up Right Icon
    </title>
    <path
      d="M0.832031 9.16683L9.16536 0.833496M9.16536 0.833496H0.832031M9.16536 0.833496V9.16683"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(ArrowSquareDiagonalUpRightIcon);
export default ForwardRef;

