import classNames from "classnames";
import { CircularButtonProps } from "./CircularButtonProps";

export const CircularButton = ({
  children,
  className,
  ...props
}: CircularButtonProps) => {
  return (
    <button
      type="button"
      className={classNames(
        "inline-flex items-center border rounded-full",
        className
      )}
      aria-label="Add this vehicle to saved vehicles"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </button>
  );
};
