import _Checkbox from "./Checkbox";
import _Input from "./Input";
import _Radio from "./Radio";
import _TextArea from "./TextArea";
import _Password from "./Password";

export const Form = {
  Input: _Input,
  TextArea: _TextArea,
  Checkbox: _Checkbox,
  Radio: _Radio,
  Password: _Password,
};

export {
  _Input as Input,
  _TextArea as TextArea,
  _Checkbox as Checkbox,
  _Radio as Radio,
  _Password as Password,
};
