import classNames from "classnames";
import { ComponentPropsWithoutRef, forwardRef } from "react";

type Props = {
  label: string;
} & ComponentPropsWithoutRef<"input">;

// eslint-disable-next-line no-underscore-dangle
const _Radio = forwardRef<HTMLInputElement, Props>(
  ({ id, name, label, disabled, ...props }, ref) => {
    return (
      <div className="relative flex items-start py-1.5">
        <input
          id={id}
          name={name}
          type="radio"
          disabled={disabled}
          ref={ref}
          className={classNames(
            "shadow-primary-500 text-primary-500 cursor-pointer",
            {
              "bg-background-light-300 disabled:border-other-light-100":
                disabled,
            }
          )}
          {...props}
        />
        <div className="ml-2 -mt-[1px]">
          <label
            htmlFor={id}
            className="block text-text-light-100 text-md font-semibold cursor-pointer"
          >
            {label}
          </label>
        </div>
      </div>
    );
  }
);

export default _Radio;
