import { ReactNode } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { useStepContext } from "../Steppers/Step";
import { useStepTransitionGroup } from "../Steppers/StepTransitionGroup";

type Props = {
  children: ReactNode;
  onTransitionFinished?: () => void;
};

export const DisclosureStepContent = ({ children, onTransitionFinished }: Props) => {
  const { index, active } = useStepContext();
  const { handleOnStepAfterLeave } = useStepTransitionGroup();

  return (
    <Transition
      show={active}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      afterLeave={() => {
        handleOnStepAfterLeave(index);
        onTransitionFinished?.();
      }}
    >
      <Disclosure.Panel static>{children}</Disclosure.Panel>
    </Transition>
  );
};