import {
  Children,
  ReactNode,
  createContext,
  isValidElement,
  useContext,
  useMemo,
} from "react";
import { useStepperContext } from "./Stepper";
import { useStepTransitionGroup } from "./StepTransitionGroup";

interface StepContextType {
  index: number;
  active: boolean;
  completed: boolean;
  disabled: boolean;
}

const StepContext = createContext<StepContextType>({} as StepContextType);

export function useStepContext() {
  return useContext(StepContext);
}

export type StepProps = {
  index: number;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
  children: ReactNode;
  stepRef?: (ref: HTMLDivElement) => void;
};

export const Step = ({
  index,
  active,
  completed,
  disabled,
  children,
  stepRef,
}: StepProps) => {
  const { activeStep, linear, connector } = useStepperContext();

  let [
    activeContext = false,
    completedContext = false,
    disabledContext = false,
  ] = [active, completed, disabled];

  if (activeStep === index) {
    activeContext = active !== undefined ? active : true;
  } else if (linear && activeStep > index) {
    completedContext = completed !== undefined ? completed : true;
  } else if (linear && activeStep < index) {
    disabledContext = disabled !== undefined ? disabled : true;
  }

  const contextValue = useMemo(
    () => ({
      index,
      active: activeContext,
      completed: completedContext,
      disabled: disabledContext,
    }),
    [activeContext, completedContext, disabledContext, index]
  );

  const childrenArray = Children.toArray(children).filter((step) =>
    isValidElement(step)
  );

  const { stepsRef } = useStepTransitionGroup();

  return (
    <StepContext.Provider value={contextValue}>
      <div
        id={`step-${index}`}
        ref={(ref) => {
          if (stepsRef.current && ref) {
            stepsRef.current[index - 1] = ref;
            stepRef?.(ref);
          }
        }}
        className="flex flex-col gap-inherit scroll-mt-16 lg:scroll-mt-9"
      >
        {connector && index !== 0 ? (
          <>
            {connector}
            {childrenArray}
          </>
        ) : (
          childrenArray
        )}
      </div>
    </StepContext.Provider>
  );
};
