import { useTranslation } from "next-i18next";
import { ModalProps } from "./ModalProps";
import { Modal } from "./Modal";
import { ButtonSpacing } from "../Button";

type Props = {
  onConfirm: () => void;
  onCancel?: () => void;
  onOutboundClick?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  secondaryButtonFill?: "outline" | "link";
  primaryButtonSpacing?: ButtonSpacing;
  secondaryButtonSpacing?: ButtonSpacing;
  hideSecondaryButton?: boolean;
  primaryButtonDisabled?: boolean;
} & Pick<
  ModalProps,
  | "title"
  | "modalType"
  | "isOpen"
  | "children"
  | "buttonDisplay"
  | "fitWidthToContent"
  | "customIcon"
>;

export const ConfirmModal = ({
  children,
  onConfirm,
  onCancel,
  onOutboundClick,
  isOpen,
  title,
  modalType,
  primaryButtonText,
  primaryButtonSpacing,
  secondaryButtonText,
  secondaryButtonSpacing,
  secondaryButtonFill = "outline",
  buttonDisplay = "inline",
  fitWidthToContent = false,
  hideSecondaryButton = false,
  customIcon,
  primaryButtonDisabled = false,
}: Props) => {
  const { t } = useTranslation(["common-components"]);
  const handleOnCancel = () => {
    if (onCancel) onCancel();
  };
  const handleOnClose = () => {
    if (onOutboundClick) {
      onOutboundClick();
    } else {
      handleOnCancel();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      title={title}
      modalType={modalType}
      alignment="center"
      buttonDisplay={buttonDisplay}
      fitWidthToContent={fitWidthToContent}
      customIcon={customIcon}
    >
      {children}
      <Modal.Buttons
        primaryDisabled={primaryButtonDisabled}
        primaryButtonText={primaryButtonText ?? t("common-components:yes")}
        secondaryButtonText={secondaryButtonText ?? t("common-components:no") ?? undefined}
        onPrimaryClick={onConfirm}
        onSecondaryClick={handleOnCancel}
        secondaryButtonFill={secondaryButtonFill}
        primaryButtonSpacing={primaryButtonSpacing}
        secondaryButtonSpacing={secondaryButtonSpacing}
        hideSecondaryButton={hideSecondaryButton}
      />
    </Modal>
  );
};
