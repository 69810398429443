import useEmblaCarousel from "embla-carousel-react";
import { useRef, useState, useCallback, useEffect, Children } from "react";
import { CarouselDots } from "./CarouselDots";

type Props = {
  children: React.ReactNode;
  autoplay?: boolean;
  onSelectedIndexChange?: (index: number) => void;
};
export const Carousel = ({
  autoplay = false,
  onSelectedIndexChange,
  children,
}: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const containerRef = useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [autoplayIntervalId, setAutoplayIntervalId] =
    useState<NodeJS.Timeout>();
  // we want to detect changes immidiately, so we use ref instead of state
  const isAutoplayingRef = useRef<boolean>(false);

  const startCarouselAutoplay = useCallback(() => {
    if (!emblaApi || isAutoplayingRef.current || !autoplay) return;

    const initAutoplay = setInterval(() => {
      if (!emblaApi) return;
      emblaApi.scrollNext();
    }, 5000);
    setAutoplayIntervalId(initAutoplay);
    isAutoplayingRef.current = true;
  }, [autoplay, emblaApi]);

  const stopCarouselAutoplay = useCallback(() => {
    clearInterval(autoplayIntervalId);
    isAutoplayingRef.current = false;
  }, [autoplayIntervalId]);

  const onInit = useCallback(() => {
    if (!emblaApi) return;
    setScrollSnaps(emblaApi.scrollSnapList());

    startCarouselAutoplay();
  }, [emblaApi, startCarouselAutoplay]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    const selectedScrollSnap = emblaApi.selectedScrollSnap();
    setSelectedIndex(selectedScrollSnap);
    onSelectedIndexChange?.(selectedScrollSnap);
  }, [emblaApi, onSelectedIndexChange]);

  const handleCarouselDotClick = useCallback(
    (index: number) => {
      emblaApi?.scrollTo(index);
      stopCarouselAutoplay();
    },
    [emblaApi, stopCarouselAutoplay]
  );

  useEffect(() => {
    if (!emblaApi) return;

    onInit();
    onSelect();

    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
    emblaApi.on("settle", () => {
      startCarouselAutoplay();
    });
  }, [emblaApi, onInit, onSelect, startCarouselAutoplay]);

  useEffect(() => {
    if (!containerRef.current) return undefined;

    const emblaContainer = containerRef.current;

    const mouseDownListener = () => stopCarouselAutoplay();
    const mouseUpListener = () => startCarouselAutoplay();

    /**
     * Whenever user PRESS (not click) on the carousel slides, we stop the autoplay.
     * This is to prevent a weird behavior where the carousel is changing while user is interacting with it.
     *
     * This also handles a scenario where user accidentally clicked the carousel without dragging the images.
     * In this case, restart the autoplay, with the mouseUpListener.
     *
     * However, user can also drag the carousel until their cursor is outside of the container.
     * In this case, mouseup/touchend event will not be triggered, and we don't restart autoplay.
     * So, we handle this case with "settle" event from Embla API and restart autoplay when settled.
     * https://www.embla-carousel.com/api/events/#settle
     *
     * Why restarting the autoplay timer?
     * It looks weird if the carousel scrolls immidiately after users finished interacting with it.
     * We don't want that.
     */
    emblaContainer.addEventListener("mousedown", mouseDownListener);
    emblaContainer.addEventListener("touchstart", mouseDownListener);
    emblaContainer.addEventListener("mouseup", mouseUpListener);
    emblaContainer.addEventListener("touchend", mouseUpListener);

    return () => {
      emblaContainer.removeEventListener("mousedown", mouseDownListener);
      emblaContainer.removeEventListener("touchstart", mouseDownListener);
      emblaContainer.removeEventListener("mouseup", mouseUpListener);
      emblaContainer.removeEventListener("touchend", mouseUpListener);
    };
  }, [startCarouselAutoplay, stopCarouselAutoplay]);

  useEffect(() => {
    return () => {
      stopCarouselAutoplay();
    };
  }, [stopCarouselAutoplay]);

  const carouselSlides = Children.map(children, (child, index) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={`carouse-slide-${index}`}
      className="relative flex-shrink-0 flex-grow-0 basis-full mr-4"
    >
      {child}
    </div>
  ));

  return (
    <div className="flex flex-col items-center w-full">
      <div className="relative mb-8">
        <div className="embla-viewport" ref={emblaRef}>
          <div className="embla-container" ref={containerRef}>
            {carouselSlides}
          </div>
        </div>
      </div>
      <CarouselDots
        length={scrollSnaps.length}
        selectedIndex={selectedIndex}
        onClick={handleCarouselDotClick}
      />
    </div>
  );
};
