import { RefObject, createContext, useContext } from "react";

export interface StepTransitionGroupType {
  activeStep: number;
  stepsRef: RefObject<HTMLDivElement[]>;
  /**
   * substracts the top position of the scroll target in pixel, useful for scenarios where the target is below a fixed positioned header
   */
  scrollOffsetTop: number;
}

const StepTransitionGroupContext = createContext<StepTransitionGroupType>(
  {} as StepTransitionGroupType
);

export function useStepTransitionGroup() {
  const { activeStep, stepsRef, scrollOffsetTop } = useContext(
    StepTransitionGroupContext
  );

  const onStepAfterLeaveHandler = (index: number) => {
    if (typeof window === "undefined" || !stepsRef?.current) {
      return;
    }

    const { innerHeight } = window;

    const activeStepRef = stepsRef.current[activeStep - 1];

    const { top, bottom } = activeStepRef
      ? activeStepRef.getBoundingClientRect()
      : { top: 0, bottom: 0 };
    const fullyVisible = top - scrollOffsetTop >= 0 && bottom <= innerHeight;

    if (!activeStepRef || (activeStepRef && fullyVisible)) {
      return;
    }

    if (activeStep > index) {
      /**
       * when we are going to the next step, we start scrolling down from the previous (above) step
       * otherwise, in some scenario, we need to scroll up to next (below) step because
       * the height above collapses and the scroll target (next step) is above the viewport
       */
      stepsRef.current[index - 1]?.scrollIntoView({
        block: "start",
      });
    }

    if (stepsRef.current) {
      stepsRef.current[activeStep - 1]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return {
    activeStep,
    stepsRef,
    handleOnStepAfterLeave: (index: number) => {
      /**
       * afterLeave does get executed after Headless-UI transition finished
       * however, when Disclosure expand/close, the heights of DOM and/or some Elements also change
       * so we need to take this scenario into account and wait for a very short time
       */
      setTimeout(() => {
        onStepAfterLeaveHandler(index);
      }, 20);
    },
  };
}

export default StepTransitionGroupContext;
