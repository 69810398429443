import { Disclosure } from "@headlessui/react";
import { Step, StepProps } from "../Steppers/Step";

type Props = StepProps;

export const DisclosureStep = ({ children, ...props }: Props) => {
  return (
    <Step {...props}>
      <Disclosure>{children}</Disclosure>
    </Step>
  );
};
