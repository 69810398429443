import classNames from "classnames";
import React, { Children, PropsWithChildren, ReactElement } from "react";

export type IconSize = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

type Props = {
  className?: string;
  size?: IconSize;
  rounded?: boolean;
};

const iconClass: Record<IconSize, string> = {
  xs: "h-6 w-6 text-xs",
  sm: "h-[20px] w-[20px] text-sm",
  md: "h-10 w-10 text-lg",
  lg: "h-12 w-12 text-xl",
  xl: "h-14 w-14 text-2xl",
  "2xl": "h-16 w-16 text-3xl",
};

export const BorderedIcon = ({
  className,
  children,
  size = "sm",
  rounded,
}: PropsWithChildren<Props>) => {
  let child;
  if (Array.isArray(children)) {
    child = Children.only(children[0]) as ReactElement;
  } else {
    child = Children.only(children) as ReactElement;
  }

  return (
    <div
      className={classNames(
        "bg-gray-50 p-2",
        {
          "rounded-full": rounded,
          "rounded-md": !rounded,
        },
        className
      )}
    >
      {React.cloneElement(child, {
        className: classNames(iconClass[size], child.props.className),
      })}
    </div>
  );
};

