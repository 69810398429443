import classNames from "classnames";
import { useTranslation } from "next-i18next";

export type LabelTextStyle = "gray" | "light-gray" | "white";

export type LabelProps = {
  id?: string;
  label?: string;
  textStyle?: LabelTextStyle;
  showOptional?: boolean;
};

export const Label = ({
  id,
  label,
  textStyle = "gray",
  showOptional = false,
}: LabelProps) => {
  const { t } = useTranslation(["common-components"]);

  return (
    <label
      className={classNames("inline-block text-sm font-medium", {
        "text-secondary": textStyle === "gray",
        "text-gray-50": textStyle === "light-gray",
        "text-white font-medium": textStyle === "white",
      })}
      htmlFor={id}
    >
      <p className="flex items-center justify-center gap-2">
        <span>{label}</span>
        {showOptional && (
          <span className="italic capitalize">
            {t("common-components:optional")}
          </span>
        )}
      </p>
    </label>
  );
};
