import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { forwardRef } from "react";
import { CheckIcon } from "@talladega/icons";
import { useStepContext } from "../Steppers/Step";

type Props = {
  stepLabel: string;
  header: string;
  onClick?: () => void;
  customIndexLabel?: number;
};

export const DisclosureStepLabel = forwardRef<HTMLButtonElement, Props>(
  ({ stepLabel, header, onClick, customIndexLabel }: Props, ref) => {
    const { index, active, completed } = useStepContext();

    return (
      <div>
        <Disclosure.Button
          onClick={onClick}
          className="flex items-center"
          ref={ref}
        >
          <div
            className={classNames(
              "inline-flex items-center justify-center rounded-full h-10 w-10 p-1 mr-5",
              {
                "bg-secondary": active || !completed,
                "bg-success-solid": !active && completed,
              }
            )}
          >
            {!active && !completed && (
              <h5 className="text-primary-deep kijiji:text-gray-500 autocan:text-gray-600">
                {customIndexLabel ?? index}
              </h5>
            )}
            {!active && completed && (
              <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
            )}
            {active && (
              <h5 className="text-brand-secondary font-semibold">
                {customIndexLabel ?? index}
              </h5>
            )}
          </div>

          <div className="text-start">
            <p className="text-secondary uppercase text-sm font-medium">
              {stepLabel}:
            </p>
            <h4
              className={classNames("text-primary", {
                "font-semibold kijiji:font-medium": active,
                "font-medium": !active,
              })}
            >
              {header}
            </h4>
          </div>
        </Disclosure.Button>
      </div>
    );
  }
);
