import classNames from "classnames";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useState,
} from "react";
import { Feedback } from "../Feedback";
import { Status } from "../form";

type Props = {
  label: ReactNode | ReactNode[];
  checkboxStyle?: "regular" | "bordered";
  status?: Status;
  feedback?: ReactNode;
} & ComponentPropsWithoutRef<"input">;

// eslint-disable-next-line no-underscore-dangle
const _Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      disabled,
      className,
      checkboxStyle = "regular",
      status = "default",
      feedback = "",
      ...props
    },
    ref
  ) => {
    const [onFocus, setOnFocus] = useState(false);

    return (
      <>
        <label
          className={classNames(
            "items-top text-md font-normal flex cursor-pointer text-secondary",
            {
              "my-2 w-full select-none flex-row justify-between rounded-[4px] border border-gray-50 p-3 focus:outline-none peer-checked:border-primary-bright peer-checked:text-primary-500 peer-checked:shadow-none peer-checked:ring-1 peer-checked:ring-primary-bright peer-checked:ring-offset-0 peer-focus:border-primary-bright lg:hover:border-primary-bright peer-checked:[&>svg]:text-primary-500":
                checkboxStyle === "bordered",
              "border border-primary-bright ring-primary-bright":
                onFocus && checkboxStyle === "bordered",
              "w-fit select-none flex-row-reverse justify-start py-1.5":
                checkboxStyle === "regular",
              "cursor-auto": disabled,
            },
            className
          )}
        >
          <div>{label}</div>

          <input
            id={id}
            ref={ref}
            type="checkbox"
            disabled={disabled}
            onFocus={() => setOnFocus(true)}
            onBlur={() => setOnFocus(false)}
            className={classNames(
              "mr-2 mt-[1px] h-4 w-4 cursor-pointer rounded-sm border-2 border-text-light-200 text-primary-500 focus:border-primary-500 focus:outline-none focus:ring-0 focus:ring-offset-0",
              {
                "opacity-24 disabled:cursor-auto disabled:border-other-light-100 disabled:bg-background-dark-100":
                  disabled,
              }
            )}
            {...props}
          />
        </label>
        {feedback && <Feedback status={status}>{feedback}</Feedback>}
      </>
    );
  }
);

export default _Checkbox;
